/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.mat-drawer-content {
  background-color: #f4f4f4;
}

.spinner-container-std {
  min-height: 500px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #cc3179;
  animation: spinner 0.8s linear infinite;
}

/*Notification with SnackeBar*/

.errorNotifier.mat-mdc-snack-bar-container,
.successNotifier.mat-mdc-snack-bar-container {
  position: relative;
  bottom: 5em;
}

.errorNotifier.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d22e2e;
}

.successNotifier.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #478c45;
}

.assignor-snackbar.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #fff;
  /* transform: translateY(-9em) !important; */

  .mat-mdc-snack-bar-label {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
